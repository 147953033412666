
  import { mapGetters } from 'vuex';
  import { errorHandler } from '@/utils/error-handler';
  const Cookie = process.client ? require('js-cookie') : undefined;
  import auth from '@/plugins/authMixins';

  export default {
    middleware: 'notAuthenticated',
    data() {
      return {
        version: process.env.VERSION,
        portal: '',
        error: '',
        prevRoute: null,
        loading: false,
      };
    },
    head() {
      return this.tags;
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from && from.name && !['signout', 'forgotpassword', 'resetpassword'].includes(from.name.toLowerCase())) {
          vm.prevRoute = from;
          localStorage.setItem('latestVisit', JSON.stringify(from));
        }
        if (from && from.name && from.name.toLowerCase() === 'signout') {
          localStorage.removeItem('latestVisit');
        }
      });
    },
    mixins: [auth],
    async asyncData(context) {
      try {
        const urlSlug = context.route.path.replace('/', '');
        const { query } = context.route;
        const { storeUrl } = context.store.getters.storeInformation;
        const tags = (await context.$api.stores.getPageHeadTags(storeUrl, urlSlug)).data;
        const pageData = (await context.$api.stores.getStoreSlugPage({ storeUrl, urlSlug, query })).data;

        return {
          tags,
          pageData,
        };
      } catch (e) {
        return await errorHandler(context, e);
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'auth/isAuthenticated',
        storeInformation: 'storeInformation',
      }),
    },
    methods: {
      login(user) {
        const previousPath = this.$route.query.redirect ? `${this.$route.query.redirect}` : '';
        this.logIn(Object.assign({}, user, { storeUrl: this.storeInformation.storeUrl, redirect: previousPath }));
      },
      async back() {
        if (!this.prevRoute) return this.$router.push('/');
        console.log('this.prevRoute.path', this.prevRoute.path);
        if (this.prevRoute.path === '/createaccount') this.$router.push('/');
        else if (this.prevRoute.name === 'products-search-all' && !this.prevRoute.params.pathMatch) {
          this.$router.push('/products/search/');
        } else {
          this.$router.push({
            name: this.prevRoute.name,
            params: this.prevRoute.params,
          });
        }
      },
    },
  };
